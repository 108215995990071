import React from "react"

import styled, { createGlobalStyle } from "styled-components/macro"

const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  /* Fonts */
  @font-face {
    font-family: 'xscale';
    src: url('fonts/x-scale_-webfont.woff2') format('woff2'),
         url('fonts/x-scale_-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
`

const CenterContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  background: #dbf7f4;
`

const Text = styled.div`
  font-family: 'xscale';
  font-size: 17vw;
  color: #98ded7;
  text-shadow: #8193a0 4px 11px 11px;
`

export default function Home() {
  return (
    <>
      <GlobalStyle />
      <CenterContainer>
        <Text>thezic.net</Text>
      </CenterContainer>
    </>
  )
}
